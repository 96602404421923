html {
  scroll-behavior: smooth;
}
:root {
    --color-green: #006600;
    --color-grey: #797979;
    --color-white: #FFFFFF;
    --color-black: #222224;
    --transition: all 400ms ease;
  }


  ::-webkit-scrollbar {
    display: none;

  }

  * {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    overflow: visible;
  } 

  a.router_link {
    all: unset;
  }