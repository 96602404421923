@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.text-green {
    color: var(--color-green);
  }
  .text-light {
    color: var(--color-grey);
  }
  .text-white {
    color: var(--color-white);
  }

.landing-container {
  font-family: 'Montserrat', sans-serif;
  min-height: 100vh;
  scroll-behavior: smooth;
  padding: 50px;
  position: relative;
}

.loader_background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(27, 31, 36);
}

@keyframes TitleAnimation {
  0% {left: -200px}
  100% {left: 0}
}
@keyframes TitleSpanAnimation {
  0% {color: var(--color-white)}
  100% {color: var(--color-green)}
}
@keyframes TextDescriptionAnimation {
  0% {transform: scale(0.01);}
  70% {transform: scale(1.1);}
  100% {transform: scale(1);}
}
@keyframes blurAnimation {
  0%{filter: blur(20px);transform: scale(1);}
  100% {filter: blur(3px)}
}
@keyframes fadeAnimation {
  0%{opacity: 0;}
  100% {opacity: 1;}
}



.landing-content {
    margin-top: 30px;
    max-width: 1000px;

}

.landing-header-logo {
    max-width: 300px;
    margin: auto;
    animation: fadeAnimation 1s;
    animation-timing-function:cubic-bezier(0.39, 0.32, 0, 1.02);
}

.landing-background {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100vw;
    height: 100%;
    z-index: -1;
    padding: 0;
}

.landing-background::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.3;
    z-index: 10;
}

#landing-background-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(3px);  
    transform: scale(1.03);
    
}

.text-title {
    position: relative;  
  font-size: min(8vw,70px);
    font-weight: bold;
    line-height: 1.2em;
    text-transform: capitalize;
    text-shadow: 7px 4px 15px rgba(0,0,0,0.3);  
    animation-name: TitleAnimation;
    animation-duration: 0.8s;
    animation-delay: 0.4ms;
}


.text-description {
    font-size: 3vw;
    line-height: 1.2em;
    text-shadow: 7px 4px 15px rgba(0,0,0,0.1);
    font-weight: 400;
    animation-name: TextDescriptionAnimation ;
    animation: TextDescriptionAnimation 0.8s 0.5s backwards;
    animation-delay: 0.4ms;

}


.buttons {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    max-width: 400px;
    animation: TextDescriptionAnimation 0.8s 0.5s backwards;
    animation-delay: 0.4ms;
}

.btn {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  border-radius: 40px;
  text-align: center;
  padding: 16px 50px;

  transition: var(--transition);
  cursor: pointer;
}


.btn_green {
  background-color: var(--color-green);
  color: var(--color-white);
}
.btn_green:hover {
  background-color: #04a804;
}

.btn_white {
  color: var(--color-white);
  background-color: var(--color-black);

}

a.callus-link { 
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.btn_white:hover {
  color: #04a804;
}

.phoneIcon {
  transition: transform 2s;
}


a.callus-link:active .phoneIcon {
  transform:rotate(-360deg);
  transition: 0s;
}


@media (max-width: 800px) {

    /* .landing-container {
        padding: 50px 50px 30px 50px;
    } */

  .text-title {
    font-size: 10vw;
  }
  .text-description {
    font-size: 4vw;
  }
}
@media (max-width: 500px) {
  .text-title {
    font-size: 12vw;
  }
  .text-description {
    font-size: 5vw;
  }
  .btn {
    padding: 16px 40px;
  }

  .buttons {
    justify-content: space-around;
  }

}

@media (max-width: 400px) {
  .buttons {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .btn {
    width: 80%;
    padding: 16px auto;
    margin: auto;
  }
  .btn_menu {
    width: 100%;
  }
  .buttons .router_link {
    width: 80%;
    margin: auto;
  }
}



